import React from "react"
import "./sectionBillboard1.scss"
import wheel from "./img/structured-growth-wheel-01.svg"


class SectionBillboard1 extends React.Component {

        render() {
        return (

            <div className="SectionBillboard1">
                <div className="copy">
                    <h1> Structured Growth is a methodical approach to constantly and consistently improve your business.</h1>
                </div>
                <div className="wheel">
                    <img src={wheel} alt="Structured Growth Wheel" />
                </div>

            </div>

        )
    }
}

export default SectionBillboard1






