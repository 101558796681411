import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import "../styles/homepage.scss"
import SectionBillboard1 from "../components/sectionBillboard1/SectionBillboard1";




class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showheadline:1
    }
  }


  componentDidMount() {
    this.interval = setInterval(() => this.setState({ showheadline: (this.state.showheadline===3 ? 1 : this.state.showheadline + 1) }), 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }



  render() { 
    return (
      <LayoutMain>
        <Helmet>
          <title>Structured Growth</title>
          <meta
            name="description"
            content="Structured Growth is a methodical approach to constantly and consistently improve your business."
          />
          <bodyAttributes
            className="homepage"
          />
        </Helmet>

        <div className="container-homepage">



          <div className="containerSectionBillboard1">
            <SectionBillboard1/>
          </div>


        </div>
      </LayoutMain>
    )
  }
}

export default IndexPage
